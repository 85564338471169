<template>
  <div class="main">
    <div id="header">
      <nav-header></nav-header>
    </div>
    <div id="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import navHeader from '../../components/pc/login/header'
export default {
  components: {
    navHeader
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 1920px;
  background-color: #fff;
  margin: 0 auto;
  #header {
    border-bottom: 3px solid #f8f8f8;
    background-color: #2d0940;
  }
  #content {
    overflow: hidden;
    background-color: #f1f2f6;
    width: 1920px;
    height: 100vh;
  }
}
</style>
