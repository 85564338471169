<template>
  <div class="main">
    <div class="main-left">
      <img
        @click="tohomepage"
        src="../../../assets/image/登录页/科研人logo.png"
        alt=""
      >
      <span
        :class="{active:navActive===1}"
        @click="navClick(1)"
      >
        <router-link :to="{path:'../content/homePage'}">首页</router-link>
      </span>
      <span
        :class="{active:navActive===2}"
        @click="navClick(2)"
      >
        <router-link :to="{path:'../content/reportingGuidelines'}">最新指南</router-link>
      </span>
            <span @click="navClick(6)">
        <router-link :to="{path:'../content/mySubscription'}">我的订阅</router-link>
      </span>
            <span
        :class="{active:navActive===5}"
        @click="navClick(5)"
      >
        <router-link :to="{path:'../content/depthOfTheArticle'}">深度文章</router-link>
      </span>
      <!-- <span
        :class="{active:navActive===3}"
        @click="navClick(3)"
      >
        <router-link :to="{path:'../content/politicsInTheTender'}">政采招标</router-link>
      </span> -->
      <!-- <span
        :class="{active:navActive===4}"
        @click="navClick(4)"
      >
        <router-link :to="{path:'../content/scientificResearchProject'}">立项数据</router-link>
      </span> -->
    </div>
    <div
      class="main-right"
      v-if="$store.state.islogin==='true'"
    >
      <img
        src="../../../assets/image/登录成功.png"
        alt=""
      >
    </div>
    <div
      class="main-right"
      v-else
    >
      <span @click="tologin">登录</span>
      <span
        class="active"
        @click="toregisted"
      >免费注册</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navActive: '',
      // 用户是否登录
      islogin: false
    }
  },
  mounted () {
    this.$data.islogin = localStorage.getItem('usermessage')
  },
  methods: {
    // 跳转到首页
    tohomepage () {
      this.$router.push({
        path: '/pc/content/homePage'
      })
    },
    toregisted: function () {
      this.$router.push({
        path: '/pc/loginroot/registered'
      })
    },
    tologin: function () {
      this.$router.push({
        path: '/pc/loginroot/login'
      })
    },
    navClick: function (val) {
      this.$data.navActive = val
    }
  }

}
</script>

<style lang="scss" scoped>
.main {
  width: 1200px !important;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2d0940 !important;
  .main-left {
    display: flex;
    justify-content: left;
    align-items: center;
    img {
      margin: 11px 32px;
      height: 51px;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 14px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      margin-right: 18px;
      a {
        color: #fff;
      }
    }
    span.active {
      background: #f8f8f8;
      border-radius: 12px;
      a {
        color: #2d0940;
      }
    }
  }
  .main-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    img {
      width: 46px;
    }
    span {
      cursor: pointer;
      margin-left: 18px;
      padding: 8px 12px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #fff;
    }
    span.active {
      background-color: #5e068c;
      border-radius: 4px;
      color: #fff;
    }
  }
}
</style>
